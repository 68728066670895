<template>
  <v-container fluid class="text-center">
    <Loader :loading="loading" />
    <Table-Container title="Empresas" :exportInfo="items">
      <template v-slot:buttons>
        <Basic-Btn text="Agregar" color="primary" icon="mdi-plus" @click="dialogs.new = true" :loading="loading" />
        <Basic-Btn text="Actualizar" color="success" icon="mdi-refresh" @click="refresh()" :loading="loading" />
      </template>
      <template v-slot:search>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
      </template>
      <v-data-table v-model="selected" :headers="headers" :items="items" :loading="loading" :search="search" :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }" :items-per-page="10" item-key="id" class="elevation-1" dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item)">mdi-pencil</v-icon></template
            ><span>Editar</span></v-tooltip
          >
          <v-tooltip top
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="viewAddress(item)">mdi-map-marker-outline</v-icon></template
            ><span>Direccion</span></v-tooltip
          >
          <v-tooltip top
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="getLogo(item.idempresa)">mdi-image</v-icon></template
            ><span>Logo</span></v-tooltip
          >
          <v-tooltip right
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="error" dark v-bind="attrs" v-on="on" :disabled="loading" @click="remove(item.idempresa)">mdi-delete</v-icon></template
            ><span>Eliminar</span></v-tooltip
          >
        </template>
        <template v-slot:[`item.empresas_direcciones`]="{ item }">
          {{ item.empresas_direcciones.length > 0 ? "Disponible" : "No disponible" }}
        </template>
        <template v-slot:[`footer.page-text`]="props"> Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }} </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </Table-Container>
    <Dialog-Form :estate="dialogs.new" title="Nueva empresa">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Guardar" color="success" icon="mdi-check" @click="save()" :loading="loading" />
      </template>
      <v-form ref="formNew" lazy-validation>
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field v-model="forms.new.nombre" label="Nombre" prepend-icon="mdi-domain" :rules="rules.nombre" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field v-model="forms.new.razon_social" label="Razón social" prepend-icon="mdi-briefcase-outline" :rules="rules.razon_social" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field v-model="forms.new.rfc" label="RFC" prepend-icon="mdi-card-text-outline" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="forms.new.telefono" label="Teléfono" prepend-icon="mdi-phone" :rules="rules.telefono" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="forms.new.email" label="Correo" prepend-icon="mdi-email" :rules="rules.email" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>
    <Dialog-Form :estate="dialogs.update" title="Actualizar empresa">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Actualizar" color="success" icon="mdi-check" @click="update()" :loading="loading" />
      </template>
      <v-form ref="formUpdate" lazy-validation>
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field v-model="forms.update.nombre" label="Nombre" prepend-icon="mdi-domain" :rules="rules.nombre" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field v-model="forms.update.razon_social" label="Razón social" prepend-icon="mdi-briefcase-outline" :rules="rules.razon_social" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field v-model="forms.update.rfc" label="RFC" prepend-icon="mdi-card-text-outline" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="forms.update.telefono" label="Teléfono" prepend-icon="mdi-phone" :rules="rules.telefono" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="forms.update.email" label="Correo" prepend-icon="mdi-email" :rules="rules.email" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>

    <Dialog-Form :estate="dialogs.logo" title="Logo">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Actualizar" color="success" icon="mdi-check" @click="updateLogo()" :loading="loading" />
      </template>
      <v-form ref="formLogo" lazy-validation>
        <v-row>
          <v-col cols="12" md="12">
            <v-file-input v-model="forms.logo.file" chips show-size label="Seleccionar imagen..." accept="image/png" :disabled="loading" outlined dense></v-file-input>
          </v-col>
          <v-col v-if="forms.logo.image" cols="12" md="12">
            <v-card color="blue-grey">
              <v-card-text>
                <v-img :src="forms.logo.image" max-height="500" contain />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>

    <Dialog-Form :estate="dialogs.address" title="Actualizar direccion">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Actualizar" color="success" icon="mdi-check" @click="saveAddress()" :loading="loading" />
      </template>
      <v-form ref="formUpdate" lazy-validation>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field v-model="forms.address.pais" label="País" prepend-icon="mdi-city-variant-outline" :rules="rules.address50" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="forms.address.estado" label="Estado" prepend-icon="mdi-city" :rules="rules.address50" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field v-model="forms.address.municipio" label="Municipio" prepend-icon="mdi-home-city-outline" :rules="rules.address50" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field type="number" v-model.number="forms.address.cp" label="CP" prepend-icon="mdi-pound" :rules="rules.addressValid" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="forms.address.colonia" label="Colonia" prepend-icon="mdi-home-group" :rules="rules.address50" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="forms.address.sector" label="Sector" prepend-icon="mdi-home-city" :rules="rules.address50" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field v-model="forms.address.calle" label="Calle" prepend-icon="mdi-home-outline" :rules="rules.address100" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="forms.address.num_ext" label="Numero exterior" prepend-icon="mdi-numeric-1-box-outline" :rules="rules.address20" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="forms.address.num_int" label="Numero interior" prepend-icon="mdi-numeric-2-box-outline" :rules="rules.address20" :disabled="loading" outlined dense></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field v-model="forms.address.entre_calles" label="Entre calles" prepend-icon="mdi-car-traction-control" :rules="rules.address100" :disabled="loading" outlined dense></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field v-model="forms.address.referencias" label="Referencias" prepend-icon="mdi-home-search-outline" :rules="rules.address100" :disabled="loading" outlined dense></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>
    <Delete-Message :estate="dialogs.remove" msg="¿Realmente desea eliminar la empresa?">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="disabled" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Eliminar" color="error" icon="mdi-check" @click="remove()" :loading="loading" />
      </template>
    </Delete-Message>

    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </v-container>
</template>

<script>
import services from "@/utils/services";
import { Loader, DialogForm, BasicAlert, TableContainer, BasicBtn, DeleteMessage } from "@/components";

export default {
  name: "company",
  components: {
    Loader,
    DialogForm,
    BasicAlert,
    TableContainer,
    BasicBtn,
    DeleteMessage,
  },
  data: () => ({
    loading: false,
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    search: "",
    headers: [
      { text: "ID", align: "left", sortable: true, value: "idempresa" },
      { text: "Nombre", align: "left", sortable: true, value: "nombre" },
      { text: "Razón social", align: "left", sortable: true, value: "razon_social" },
      { text: "RFC", align: "left", sortable: true, value: "rfc" },
      { text: "Telefono", align: "left", sortable: true, value: "telefono" },
      { text: "Correo", align: "left", sortable: true, value: "email" },
      { text: "Direccion", align: "left", sortable: true, value: "empresas_direcciones" },
      { text: "Acciones", align: "left", sortable: false, value: "actions" },
    ],
    items: [],
    selected: [],
    deleted: null,
    menu: false,
    forms: {
      new: {
        nombre: "",
        razon_social: "",
        rfc: "",
        telefono: "",
        email: "",
        no_empleados: "",
        sector_profesional: "",
      },
      update: {
        idempresa: "",
        nombre: "",
        razon_social: "",
        rfc: "",
        telefono: "",
        email: "",
        no_empleados: "",
        sector_profesional: "",
      },
      address: {
        calle: "",
        calle: "",
        num_ext: "",
        num_int: "",
        entre_calles: "",
        referencias: "",
        colonia: "",
        sector: "",
        cp: "",
        municipio: "",
        estado: "",
        sector_profesional: "",
        pais: "",
        referencia: "",
      },

      logo: {
        empresa: null,
        file: null,
        image: null,
      },
      idUpdate: 0,
    },
    rules: {
      nombre: [(v) => !!v || "El campo es requerido", (v) => (v && v.length <= 250) || "El campo excede la longitud máxima"],
      razon_social: [(v) => !!v || "El campo es requerido", (v) => (v && v.length <= 250) || "El campo excede la longitud máxima"],
      telefono: [(v) => !!v || "El campo es requerido", (v) => (v && v.length <= 15) || "El campo excede la longitud máxima"],
      email: [(v) => !!v || "El campo es requerido", (v) => (v && v.length <= 320) || "El campo excede la longitud máxima", (v) => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "El campo es inválido"],
      addresValid: [(v) => !!v || "El campo es requerido"],
      addres20: [(v) => (v && v.length <= 20) || "El campo excede la longitud máxima"],
      addres50: [(v) => (v && v.length <= 50) || "El campo excede la longitud máxima"],
      addres100: [(v) => (v && v.length <= 100) || "El campo excede la longitud máxima"],
    },
    dialogs: {
      new: false,
      update: false,
      address: false,
      logo: false,
      remove: false,
    },
  }),
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.loading = true;
      this.axios
        .get(services.routes.company)
        .then((response) => {
          this.items = response.data.data;
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      if (this.dialogs.new) {
        this.$refs.formNew.reset();
        for (let key in this.forms.new) {
          this.forms.new[key] = "";
        }
        this.dialogs.new = false;
      } else if (this.dialogs.update) {
        this.dialogs.update = false;
      } else if (this.dialogs.address) {
        for (let key in this.forms.address) {
          if (key == "cp") {
            this.forms.address[key] = 0;
          } else {
            this.forms.address[key] = "";
          }
        }
        this.dialogs.address = false;
      } else if (this.dialogs.logo) {
        this.$refs.formLogo.reset();
        this.forms.logo = {
          empresa: null,
          file: null,
          image: null,
        };
        this.dialogs.logo = false;
      } else if (this.dialogs.remove) {
        this.dialogs.remove = false;
      }
      this.menu = false;
      this.dialogs.sources = false;
      this.dialogs.users = false;
    },
    save() {
      if (this.$refs.formNew.validate()) {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.forms.new));
        this.axios
          .post(services.routes.company, { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Empresa creada exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "La información es incorrecta";
      }
    },
    view(data) {
      this.forms.update = JSON.parse(JSON.stringify(data));
      this.dialogs.update = true;
    },
    viewAddress(data) {
      this.idUpdate = data.idempresa;
      if (data.empresas_direcciones.length > 0) {
        for (let key in this.forms.address) {
          if (data.empresas_direcciones[0].direccion[key]) {
            this.forms.address[key] = data.empresas_direcciones[0].direccion[key];
          }
        }
      }
      this.dialogs.address = true;
    },
    getLogo(id) {
      this.loading = true;
      this.axios
        .get(services.routes.company + "/logo?id=" + id)
        .then((response) => {
          let data = response.data.data;
          this.forms.logo.empresa = id;
          if (data) {
            data = data.data;
            this.forms.logo.image = "data:image/png;base64," + btoa(new Uint8Array(data).reduce((data, byte) => data + String.fromCharCode(byte), ""));
          }
          this.dialogs.logo = true;
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateLogo() {
      if (this.forms.logo.file) {
        let fr = new FileReader();
        let file = this.forms.logo.file;
        let size = file.size;
        if (size <= 1000000) {
          fr.onload = () => {
            this.loading = true;
            let data = {
              idempresa: this.forms.logo.empresa,
              logo: fr.result.split(",")[1],
            };
            this.axios
              .put(services.routes.company + "/logo", { data })
              .then((response) => {
                this.cancel();
                this.refresh();
                this.alert.active = true;
                this.alert.color = "success";
                this.alert.msg = "Logo actualizado exitosamente";
              })
              .catch((error) => {
                this.alert.active = true;
                this.alert.color = "error";
                this.alert.msg = error.response.data.message || "Servicio no disponible";
              })
              .finally(() => {
                this.loading = false;
              });
          };
          fr.onerror = () => {
            this.loading = false;
            this.forms.logo.file = null;
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = "El archivo no fue leído correctamente";
          };
          fr.readAsDataURL(file);
        } else {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = "El archivo ha excedido el límite permitido (1 MB)";
        }
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "Seleccionar un archivo";
      }
    },
    saveAddress() {
      this.loading = true;
      let aux = this.forms.address;
      let data = {
        empresa: this.idUpdate,
        direccion: aux,
      };
      this.axios
        .put(services.routes.company + "/address", { data })
        .then(() => {
          this.cancel();
          this.refresh();
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      if (this.dialogs.compensations) {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.forms.update));
        this.axios
          .put(services.routes.company, { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Compensaciones actualizadas exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else if (this.$refs.formUpdate.validate()) {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.forms.update));
        this.axios
          .put(services.routes.company, { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Empresa actualizada exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "La información es incorrecta";
      }
    },
    remove(id) {
      if (!id) {
        this.loading = true;
        let id = this.deleted;
        this.axios
          .delete(services.routes.company, { data: { data: { idempresa: this.deleted } } })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Empresa eliminada exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.deleted = id;
        this.dialogs.remove = true;
      }
    },
  },
};
</script>

<style scoped></style>
